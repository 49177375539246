import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SideBarSection } from "../../molecules/SideBarSection";
import { MenuProps } from "../../../common/types/SideBar.types";

const SideBar: React.FC = () => {
  const sectionItems: MenuProps[] = [
    { title: "ユーザー", to: "/users" },
    { title: "プラン", to: "/plans" },
    { title: "投稿", to: "/articles" }
  ];

  return (
    <Root>
      <Brand to={""}>
        <BrandText>
          {process.env.REACT_APP_BLAND_NAME}
          <sup>Admin</sup>
        </BrandText>
      </Brand>
      <SideBarDevider isTop={true} />
      <SideBarSection sectionItems={sectionItems} />
      <SideBarDevider isTop={false} />
    </Root>
  );
};

export default SideBar;

const Root = styled.ul`
  background-color: #693c26;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 14rem !important;
  min-height: 100vh;
`;

const Brand = styled(Link)`
  justify-content: center !important;
  display: flex !important;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
  align-items: center !important;
`;

const BrandText = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const SideBarDevider = styled.hr<{ isTop: boolean }>`
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin: 0 1rem ${props => (props.isTop ? "0" : "1rem")};
`;
